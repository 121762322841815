import * as React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { useMemo, useRef, useState } from 'react'
import Container from '../components/Container'
import NarrowLayout from '../layouts/NarrowLayout'
import { colors } from '../styles/variables'
import MainLayout from '../layouts/MainLayout'
import { shuffleArr } from '../utils/shuffle'

const ImagesWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;

  & > div {
    position: relative;
    height: 100%;
    width: 100%;
  }
  @media(max-width: 1100px){
    width: 100%;
    height: 50%;
  }
`
const ArtistsListWrapper = styled.div`
  width: 50%;
  float: right;
  margin-top: 20vh;
    @media(max-width: 1100px){
    float: none;
    width: 100%;
    margin-top: 55vh;
  }
`
const ArtistListItem = styled.li<{ active: boolean; color: string }>`
  display: block;
  cursor: pointer;
  font-size: 4em;
  text-transform: uppercase;
  letter-spacing: 10px;

  color: rgba(255, 255, 255, 0.3);
  font-family: Poppins;
  transition: all 0.2s;
  text-shadow: 3px 3px 4px rgba(255, 255, 255, 0.3);
  ${props =>
    props.active &&
    `
    -webkit-text-stroke: 2px ${props.color};
    -webkit-text-fill-color: ${colors.black};
    text-shadow: 5px 5px 10px ${props.color};
    margin-left: 2px;
  `}
  a, a:visited {
    all: unset;
  }
  @media(max-width: 1100px){
    font-size: 3em;
  }
  @media(max-width: 500px){
    font-size: 2em;
  }
`
const ArtistImageWrapper = styled.div<{ active: boolean }>`
  opacity: ${props => (props.active ? '1' : '0')};
  transition: 0.2s all;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);

  & > div {
    height: 100%;
  }
`
const SmallArtistImageWrapper = styled(ArtistImageWrapper)`
  width: 100%;
  height: 100%;
  z-index: 2;
  filter: none;
  & > div {
    position: absolute;
    max-width: 80%;
    max-height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
const ArtistsPage = () => {
  return null
  // const [hoveredArtist, setHoveredArtist] = useState<string | null>('sweeper')
  // const { artists } = useStaticQuery(graphql`
  //   query {
  //     artists: allMarkdownRemark(filter: { frontmatter: { type: { eq: "artist" } } }) {
  //       edges {
  //         node {
  //           fileAbsolutePath
  //           frontmatter {
  //             id
  //             name
  //             color
  //             artistImage {
  //               childImageSharp {
  //                 fluid {
  //                   ...GatsbyImageSharpFluid
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)
  // const artistsArr = useMemo(() => shuffleArr(artists.edges), []);
  //
  // return (
  //   <MainLayout>
  //     <ImagesWrapper>
  //       <div>
  //         {artistsArr.map(({ node }) => {
  //           console.log(node)
  //           const info = node.frontmatter
  //           return (
  //             <>
  //               <ArtistImageWrapper active={hoveredArtist === info.id}>
  //                 <Img fluid={info.artistImage.childImageSharp.fluid} />
  //               </ArtistImageWrapper>
  //               <SmallArtistImageWrapper active={hoveredArtist === info.id}>
  //                 <Img fluid={info.artistImage.childImageSharp.fluid} />
  //               </SmallArtistImageWrapper>
  //             </>
  //           )
  //         })}
  //       </div>
  //     </ImagesWrapper>
  //     <ArtistsListWrapper>
  //       <ul>
  //
  //         {artistsArr.map(({ node }) => {
  //           const info = node.frontmatter;
  //           return (
  //             <ArtistListItem active={hoveredArtist === info.id} onMouseOver={() => setHoveredArtist(info.id)} color={info.color}>
  //               <Link to={`/artists/${info.id}`}>
  //                 {info.name}
  //               </Link>
  //             </ArtistListItem>
  //           )
  //         })}
  //       </ul>
  //     </ArtistsListWrapper>
  //   </MainLayout>
  // )
}

export default ArtistsPage
